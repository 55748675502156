<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 29 26"
  >
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M10.3 13h0m8 0h0m-6.7 5.4c.7.4 1.6.7 2.7.7 1 0 2-.3 2.7-.7"
    />
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M23.7 5.4a12 12 0 0 1 2.4 5.2 2.7 2.7 0 0 1 0 4.9 12 12 0 0 1-23.6 0 2.7 2.7 0 0 1 0-4.9A12 12 0 0 1 14.3 1C17 1 19 2.5 19 4.3c0 2-1.2 3.4-2.7 3.4-1 0-2-.5-2-1.3"
    />
  </svg>
</template>
